import React, { useState, useEffect, useRef } from 'react';

function App() {
  const [tasks, setTasks] = useState([]);
  const [progress, setProgress] = useState(0);
  const [zoom, setZoom] = useState(false);
  const [yRotation, setYRotation] = useState(0);
  const [xRotation, setXRotation] = useState(0);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const timeoutRef = useRef(null); // Usando useRef para criar a referência mutável

  useEffect(() => {
    fetch('https://raw.githubusercontent.com/byy0x/tracker-progress/main/progress.json')
      .then(response => response.json())
      .then(data => {
        setTasks(data);
        const completedTasks = data.filter(task => task.completed).length;
        const totalTasks = data.length;
        const progressPercentage = Math.floor((completedTasks / totalTasks) * 100);
        setProgress(progressPercentage);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    const mouseMove = (e) => {
      setMouseX(e.clientX);
      setMouseY(e.clientY);
      setOpacity(1);

      clearTimeout(timeoutRef.current); // Utilizando a referência atualizada
      timeoutRef.current = setTimeout(() => {
        setOpacity(0);
      }, 1000);
    };

    document.addEventListener('mousemove', mouseMove);

    return () => {
      document.removeEventListener('mousemove', mouseMove);
      clearTimeout(timeoutRef.current); // Limpeza ao desmontar o componente
    };
  }, []);

  const completedTasks = tasks.filter(task => task.completed);
  const remainingTasks = tasks.filter(task => !task.completed);

  const enterRotate3D = (e) => {
    const card = e.currentTarget.querySelector('.task-card');
    const cardRect = card.getBoundingClientRect();
    const offsetX = (e.clientX - cardRect.left - cardRect.width / 2) / (cardRect.width / 2);
    const offsetY = (e.clientY - cardRect.top - cardRect.height / 2) / (cardRect.height / 2);
    const maxRotation = 10;
    let targetYRotation = maxRotation * offsetX;
    let targetXRotation = -maxRotation * offsetY;

    const updateRotation = () => {
      if (Math.abs(yRotation - targetYRotation) > 0.1 || Math.abs(xRotation - targetXRotation) > 0.1) {
        setYRotation(yRotation + (targetYRotation - yRotation) * 0.1);
        setXRotation(xRotation + (targetXRotation - xRotation) * 0.1);
        requestAnimationFrame(updateRotation);
      }
    };

    setZoom(true);
    requestAnimationFrame(updateRotation);
  };

  const leaveRotate3D = () => {
    const targetYRotation = 0;
    const targetXRotation = 0;

    const updateRotation = () => {
      if (Math.abs(yRotation - targetYRotation) > 0.1 || Math.abs(xRotation - targetXRotation) > 0.1) {
        setYRotation(yRotation + (targetYRotation - yRotation) * 0.1);
        setXRotation(xRotation + (targetXRotation - xRotation) * 0.1);
        requestAnimationFrame(updateRotation);
      } else {
        setZoom(false);
      }
    };

    requestAnimationFrame(updateRotation);
  };
      return (
        <div className="App">
          <style>
            {`
              /* Seus estilos antigos aqui */
              :root {
                /* Colors */
                --dark-dark-500: #06060f;
                --color-blueviolet: rgba(90, 69, 254, 0.8);
              }
              
              body {
                background-color: #06060f;
                position: relative;
                overflow-x: hidden;
              }
              
              body:before {
                content: "";
                position: fixed;
                top: -338px;
                left: 1583px;
                border-radius: 50%;
                background-color: var(--color-blueviolet);
                filter: blur(1000px);
                width: 675px;
                height: 675px;
                z-index: -1;
              }
              
              body:after {
                content: "";
                position: fixed;
                top: 742px;
                left: -259px;
                border-radius: 50%;
                background-color: var(--color-blueviolet);
                filter: blur(1000px);
                width: 675px;
                height: 675px;
                z-index: -1;
              }
              ::selection {
                backdrop-filter: blur(10px);
                background-color: blueviolet;
                color: white;
              }
              body::-webkit-scrollbar {
                width: 4px;
              }
        
              body::-webkit-scrollbar-track {
                background: #141024;
              }
        
              body::-webkit-scrollbar-thumb {
                background-color: #0c0c1e;
                border-radius: 20px;
                border: 0.5px solid rgb(154, 10, 250);
              }
        
              .App {
                text-align: center;
                color: #fff;
                min-height: 100vh;
                padding-top: 50px;
              }

              .progress-bar-container {
                position: relative;
                width: 50%;
                margin: 20px auto;
                border: 1px solid #fff;
                border-radius: 100px;
              }

              .progress-bar {
                height: 20px;
                background: rgb(9,17,57);
                background: -moz-radial-gradient(circle, rgba(9,17,57,1) 0%, rgba(87,53,255,1) 100%);
                background: -webkit-radial-gradient(circle, rgba(9,17,57,1) 0%, rgba(87,53,255,1) 100%);
                background: radial-gradient(circle, rgba(9,17,57,1) 0%, rgba(87,53,255,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#091139",endColorstr="#5735ff",GradientType=1);            position: relative;
                border-radius: 100px;


              }

              .progress-bar span {
                position: relative;
                top: 0;
                transform: translateY(-100%);
                color: #fff;
                font-weight: bold;
                padding: 0 5px;
              }

              .tasks-container {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 50px;
              }

              ul {
                list-style-type: none;
                padding: 0;
              }

              .task-card {
                width: 600px;
                margin: 0 auto;
                animation: float 6s ease-in-out infinite;
                animation-play-state: running;

                padding: 20px;
                background: rgb(10,7,38);
                background: -moz-linear-gradient(138deg, rgba(10,7,38,1) 0%, rgba(46,46,122,1) 46%, rgba(6,6,15,1) 100%);
                background: -webkit-linear-gradient(138deg, rgba(10,7,38,1) 0%, rgba(46,46,122,1) 46%, rgba(6,6,15,1) 100%);
                background: linear-gradient(138deg, rgba(10,7,38,1) 0%, rgba(46,46,122,1) 46%, rgba(6,6,15,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a0726",endColorstr="#06060f",GradientType=1);
                            border-radius: 10px;
                color: #fff;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
              }
              .task-card::before {
                content: "";
                position: absolute;
                top: -20px;
                left: -20px;
                right: -20px;
                bottom: -20px;
                background-color: rgba(90, 69, 254, 0.1); /* Cor e opacidade da luz roxa */
                border-radius: 10px; /* Ajuste conforme necessário */
                z-index: -1;
              }
              .task-column {
                width: 100%;
                margin-bottom: 20px;
              }

              .task-item {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                border-bottom: 1px solid #555;
                padding-bottom: 5px;
              }

              .task-item img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }

              .task-title {
                font-size: 24px;
                text-align: left;
                width: 100%;
                margin-bottom: 20px;
              }

              .completed-tasks {
                color: #00ff00;
              }

              .remaining-tasks {
                color: #ff0000;
              }

              @media (min-width: 768px) {
                .task-column {
                  width: calc(50% - 10px);
                }
              }
              @keyframes float {
                0% {
                  transform: translateY(7px);
                }
                50% {
                  transform: translateY(-7px);
                }
                100% {
                  transform: translateY(7px);
                }
              }
              .pointer-ring {
                left: 0;
                top: 0;
                height: 30px;
                width: 30px;
                border-radius: 100px;
                border: 1px solid white; 
                position: fixed;
                z-index: 30;
                opacity: 0;
                pointer-events: none;
                transition: 0.3s all cubic-bezier(0.25, 0.46, 0.45, 0.94);

                @media (pointer: coarse) {
                  display: none;
                }
              }
            `}
          </style>
          <div
            className="pointer-ring"
            style={{
              transform: `translateX(${mouseX - 15}px) translateY(${mouseY - 15}px)`,
              opacity: opacity
            }}
          />
          <h1>Progresso do Tracker</h1>
          <div
            className="tasks-container"
            onMouseMove={enterRotate3D}
            onMouseLeave={leaveRotate3D}
            style={{
              transform: `perspective(1000px) ${zoom ? 'scale(1.0)' : ''} rotateX(${xRotation}deg) rotateY(${yRotation}deg)`
            }}
          >
            <div className="task-card">
              <div className="task-column">
                <h2 className="task-title">Tarefas Concluídas</h2>
                <ul>
                  {completedTasks.map(task => (
                    <li key={task.id} className="task-item completed-tasks">
                      <img src="https://emojicdn.elk.sh/✅" alt="checkmark" />
                      {task.description}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="task-column">
                <h2 className="task-title">Tarefas Restantes</h2>
                <ul>
                  {remainingTasks.map(task => (
                    <li key={task.id} className="task-item remaining-tasks">
                      <img src="https://emojicdn.elk.sh/❌" alt="crossmark" />
                      {task.description}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <h1>Progresso total</h1>
          <p>A medida do progresso total é determinada pela conclusão das tarefas,<br />podendo diminuir à medida que mais itens são adicionados à lista de afazeres.</p>
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}>
              <span>% {progress}</span>
            </div>
          </div>
        </div>
      );
    }

    export default App;
